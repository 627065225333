@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  text-align: center;
  padding: 32px;
}

.title {
  color: #fff;
  line-height: 120%;
}

.subtitle {
  color: #fff;
  margin-top: fn.spacing(3.5);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .section {
    padding: 64px;
  }

  .subtitle {
    margin-top: 32px;
  }
}
